@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';

.GarageFooterSection {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  border-top: 1px solid colors.$neutral200;
}

.BookingsNumberSection {
  margin-right: spacings.$XXXS;
}

.BookingsNumberSection,
.YearsInBusinessSection {
  text-align: center;

  > div:first-of-type {
    @include typography.labelMTypographyStyles;
    color: colors.$purple500;
  }

  > div:nth-of-type(2) {
    @include typography.paragraphSTypography;
    color: colors.$neutral400;
  }
}
