@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/values";
@use '$styles/mediaqueries';

.component {
    color: values.$colorWhite;
    background-color: values.$colorNeutral600;
    padding: values.$space14 0 values.$space12;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li a {
            color: values.$colorWhite;
            font-size: values.$px_14;
            font-weight: 500;
            line-height: 200%; /* 28px */
        }
    }
}

.logo {
    order: 1;
    &__image {
        @include mediaqueries.mediumUp {
            width: 174px;
            height: 31px;
        }
        @include mediaqueries.largeUp {
            width: 196px;
            height: 35px;
        }
    }
}

.forCustomers {
    order: 2;
    margin-top: values.$space11;
    @include mediaqueries.mediumUp {
        grid-row: 1/3;
        margin-top: 0;
    }
}

.forWorkshops {
    order: 3;
    margin-top: values.$space6;
    @include mediaqueries.mediumUp {
        grid-row: 1/3;
        margin-top: 0;
    }
}

.weAreMotrio {
    order: 4;
    margin-top: values.$space6;
    @include mediaqueries.mediumUp {
        order: 2;
        margin-top: values.$space12;
    }
    @include mediaqueries.largeUp {
        order: 2;
        margin-top: values.$space16;
    }
}

.socialMedia {
    margin-top: values.$space6;
    margin-left: calc(values.$space1 * -1);
    &__link {
        margin-right: values.$space1;
        &:last-child {
            margin-right: 0;
        }
    }
    :global(.qvIcon) {
        color: values.$colorWhite;
    }
}

.sectionTitle {
    color: values.$neutral300;
    font-size: values.$px_14;
    font-weight: 700;
    line-height: 160%; /* 22.4px */
}

.footerEnd {
    margin-top: values.$space10;
    display: flex;
    align-items: center;
    ul {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        li {
            margin-right: values.$space4;
            white-space: nowrap;
            font-size: values.$px_12;
            font-weight: 500;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}