@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/values";

.component {
  position: relative;

  .heroSection {
    height: 150px;
    color: values.$colorWhite;
    background-color: values.$colorRed500;
    padding-top: values.$space8;
    padding-bottom: values.$space8;
  }

  .garageMapPositionSticky {
    @include mediaqueries.mediumUp {
      position: sticky;
      top: 0;
    }
  }
  .noGarageMapPositionSticky {
    position: initial;
  }

  .garageMapPosition {
    @include mediaqueries.mediumUp {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: calc(100vh - 150px);
    }
  }
  .garageMapPositionShort {
    @include mediaqueries.mediumUp {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: calc(100% - 150px);
    }
  }

  .bottomMap {
    top: calc(100% - 100vh + 80px - 25px);
  }
  .bottomMapShort {
    height: calc(100% - 150px);
  }

  .noGarages {
    min-height: 400px;
    padding-top: values.$space8;
  }

  .garageListWrapper {
    overflow: hidden;
    max-height: 2000px;
    position: relative;

    &.show {
      max-height: 100%;
    }
  }

  .hideMask {
    position: absolute;
    bottom: 0px;
    height: 200px;
    width: 100%;
    background: linear-gradient(to bottom, transparent 0, white 80%, white 100%);
  }

  .garageInfoCard {
    :global(.GarageInfoCardHeader) {
      color: values.$colorNeutral800;
    }
    :global(.ButtonCta) {
      color: values.$colorWhite;
    }
  }
}
