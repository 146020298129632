@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/spacings';

.HeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacings.$M;
}

.RatingsSection {
  display: flex;
  align-items: center;

  .RatingNumber {
    font-family: Jokker;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: colors.$neutral800;
  }
}

.ReviewStarsSection {
  display: flex;
  margin: -3px 5px 0;
}

.ReviewsNumber {
  color: colors.$neutral400;
  font: fonts.$labelXS;
  font-weight: 600;
}
