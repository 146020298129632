@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/values';

.component {
  :global(.GoogleReviews h2) {
    display: none;
  }
}

.GaragePageContent {
  padding-top: values.$space6;
  padding-bottom: values.$space6;

  @include mediaqueries.upToMedium {
    padding-bottom: 0px;
    position: relative;
    margin-bottom: calc(values.$space14 * -1);
    z-index: 2;

    display: flex;
    flex-direction: column;

    .CTAListColumn {
      order: 3;
    }
    .AboutUsColumn {
      order: 1;
    }
    .LocationColumn {
      order: 2;
    }
  }
}

.GarageAboutUsCardComponent {
  @include mediaqueries.upToMedium {
    margin-top: calc(values.$space20 * -1);
    margin-bottom: values.$space3;
    order: 1;
  }

  @include mediaqueries.mediumUp {
    box-shadow: unset !important;
    margin-bottom: values.$space20;
  }
}


.GarageServicesCTAListComponent {
  @include mediaqueries.upToMedium {
    order: 2;
    margin-bottom: values.$space3;
  }

  @include mediaqueries.mediumUp {
    margin-top: calc(values.$space20 * -1);
    margin-bottom: values.$space10;
    position: relative;
  }
}

.GarageLocation {
  @include mediaqueries.upToMedium {
    margin-bottom: values.$space3;
  }

  @include mediaqueries.mediumUp {
    margin-bottom: -100px !important;
    display: flex;
    gap: values.$space20;
    box-shadow: unset;
    border: 1px solid values.$colorNeutral200;
  }
}

.desktop {
  @include mediaqueries.upToMedium {
    display: none;
  }
}

.mobile {
  @include mediaqueries.mediumUp {
    display: none;
  }
}